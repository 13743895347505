<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8 mx-auto">
        <div class="card border-0 shadow">
          <div class="card-body py-5 text-center">
            <img
              src="@/assets/images/brand/navy-gold-med.png"
              alt="Swandoola"
              class="mb-5"
              width="200"
            />
            <h6 class="mb-5">
              We have unsubscribed you from any future marketing emails, if you
              are a clinic or practitioner you will continue to receive system
              emails.
            </h6>
            <a href="https://swandoola.com" class="btn btn-primary">
              <i class="far fa-home me-2"></i>Swandoola Home
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { done: false };
  },
  methods: {
    unsubscribe() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/marketing/unsubscribe/" +
            this.$route.params.userId
        )
        .then(({ data }) => {
          this.done = true;
        });
    },
  },
  created() {
    this.unsubscribe();
  },
};
</script>

<style>
</style>